<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) What are the van der Waals constants for
        <chemical-latex :content="molecule + '?'" />
      </p>

      <calculation-input
        v-model="inputs.a"
        class="mb-3"
        dense
        prepend-text="$\text{a:}$"
        append-text="$\text{L}^2 \text{atm} / \text{mol}^2$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.b"
        class="mb-5"
        dense
        prepend-text="$\text{b:}$"
        append-text="$\text{L/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Using the information in part a) and the van der Waals equation, estimate the pressure
        exerted by
        <stemble-latex content="$1.000\,\text{mol}$" />
        of
        <chemical-latex :content="molecule" />
        confined to a volume of
        <number-value :value="volume" unit="\text{L}" />
        at
        <number-value :value="temp" unit="\text{K.}" />
      </p>

      <calculation-input
        v-model="inputs.pressure"
        prepend-text="$\text{Pressure:}$"
        append-text="$\text{atm}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question397',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        a: null,
        b: null,
        pressure: null,
      },
    };
  },
  computed: {
    molVersion() {
      return this.taskState.getValueBySymbol('molVersion').content;
    },
    molecule() {
      if (this.molVersion.value === 1) {
        return 'N2';
      } else if (this.molVersion.value === 2) {
        return 'O2';
      } else if (this.molVersion.value === 3) {
        return 'CO2';
      } else if (this.molVersion.value === 4) {
        return 'He';
      } else {
        return 'Error!';
      }
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
